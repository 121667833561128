<template>
    <div class="cityAbility">
        <div class="swiper-container pageTop_box" id="cityAbilityTop_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item+')'"></div>
                </div>
            </div>
            <div class="pagination cityAbilityTop-pagination"></div>
        </div>

        <div class="box_ home_2_box src-left">
            <div class="desc_info" v-html="infoD.desc"></div>
            <div class="btn-list">
                <div class="svg-box" v-for="item in home2.list" :key="item.title">
                    <img :src="item.image">
                    <span>{{item.title}}</span>
                    <svg class="svg-border"><path d="M0 0 L384 0 L384 128 L0 128 L0 0"></path></svg>
                </div>
            </div>
        </div>   

        <!-- 专家智库 -->
        <expert-think-tank class="home_3_box src-bottom-1" :info="home3"></expert-think-tank>


        <!-- 高峰论坛 -->
        <div class="home_4_box">
            <div class="box_">
                <div class="lineLeft_box_title w_">{{peakBbs.name||'高峰论坛'}}</div>
                <div class="info_" v-html="peakBbs.desc"></div>
                <div class="list-box">
                    <div v-for="item in peakBbs.list" :key="item.id">
                        <div class="img_box">
                            <div :style="'background-image: url('+item.thumbnail+')'"></div>
                        </div>
                        <div class="name">{{item.title}}</div>
                        <div class="desc_" v-html="item.content"></div>
                    </div>
                </div>   
            </div>
        </div>

        <!-- 政策解读 -->
        <div class="box_ home_5_box">
            <div class="top_">
                <div class="lineLeft_box_title">{{policyInter.name||'政策解读'}}</div>
                <button class="than_more" @click="onGetMore(policyInter.name, policyInter.category_id)">查看更多></button>
            </div>
            <div class="info_list_box">
                <div class="tap_" v-for="item in policyInter.list" :key="item.id" @click="onInfoDetail(item.id)">
                    <div class="img_box"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                    <div class="info">{{item.title}}</div>
                </div>
            </div>
        </div>

        
        <!-- 产业研究 -->
        <div class="box_ home_5_box second_">
            <div class="top_">
                <div class="lineLeft_box_title">{{industryResearch.name||'产业研究'}}</div>
                <button class="than_more" @click="onGetMore(industryResearch.name, industryResearch.category_id)">查看更多></button>
            </div>
            <div class="info_list_box">
                <div class="tap_" v-for="item in industryResearch.list" :key="item.id" @click="onInfoDetail(item.id)">
                    <div class="img_box"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                    <div class="info">{{item.title}}</div>
                </div>
            </div>
        </div>

        <!-- 核心业务 -->
        <div class="home_6_box">
            <div class="box_ home_box_title">
                <span>{{infoD.one_name||'核心业务'}}</span>
                <div class="english absol_">CORE BUSINESS</div>
            </div>
            
            <!-- 一园区 -->
            <div class="box_ one_box">
                <div class="lineLeft_box_title">{{aPark.name||'一园区'}}</div>  
                <div class="content_">
                    <div class="swiper-container box-tab" id="cityAbility6_1_swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item in 1" :key="item">
                                <div class="img_box" :style="'background-image: url('+aPark.img+')'"></div>
                            </div>
                        </div>
                        <!-- <div class="next_prev_box">
                            <div class="cityAbility6_1_prev prev"></div>
                            <div class="cityAbility6_1_next next"></div>
                        </div> -->
                    </div>
                    <div class="info_tap src-right">
                        <div class="scrollbar_box">
                            <div class="name">{{aPark.title}}</div>
                            <div class="desc" v-html="aPark.content"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 六平台 -->
            <div class="two_box">
                <div class="box_ lineLeft_box_title">{{sixPlat.name||'六平台'}}</div>
                <div class="box_ tob_box">
                    <div v-for="(item,index) in sixPlat.list" :key="item.id" :class="{'active': selectPlatformIndex==index}" @click="selectPlatformIndex=index">
                        <div>
                            <img :src="selectPlatformIndex!=index? item.img_g : item.img_a">
                            <div class="info ellipsis">{{item.title}}</div>  
                        </div>
                    </div>
                </div>
                <div class="content_">
                    <div class="box_ img_box src-left" ref="cityAbility6_2_img">
                        <div :style="'background-image: url('+selectPlatImg+')'"></div>
                    </div>
                </div>
            </div>

            <!-- 三基地 -->
            <div class="box_ three_box">
                <div class="lineLeft_box_title">{{threeBases.name||'三基地'}}</div>
                <div class="list_box">
                    <div v-for="item in threeBases.list" :key="item.id">
                        <div class="img_box"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                        <div class="name">{{item.title}}</div>
                        <div class="info" v-html="item.content"></div>
                    </div>
                </div>
            </div>

            <!-- 一中心 -->
            <div class="box_ one_box four_box">
                <div class="lineLeft_box_title">{{aCenter.name||'一中心'}}</div>
                <div class="content_">
                    <div class="box-tab">
                         <div class="img_box" :style="'background-image: url('+aCenter.img+')'"></div>
                    </div>
                    <div class="info_tap src-right">
                        <div class="scrollbar_box">
                            <div class="name">{{aCenter.title}}</div>
                            <div class="desc" v-html="aCenter.content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 产业基金 -->
        <div class="box_ home_7_box">
            <div class="home_box_title">
                <span>{{industryFunds.name||'产业基金'}}</span>
                <div class="english absol_">INDUSTRY FUND</div>
            </div>
            <div class="lineLeft_box_title">{{industryFunds.name_1||'基金介绍'}}</div>  
            <div class="desc_info" v-html="industryFunds.content_1"></div>
            <div class="lineLeft_box_title">{{industryFunds.name_2||'管理系统'}}</div>  
            <div class="detail_img src-bottom-1">
                <img :src="industryFunds.img_2">
            </div>
            <div class="lineLeft_box_title">{{industryFunds.name_3||'管理流程'}}</div>  
            <div class="detail_img src-bottom-1">
                <img :src="industryFunds.img_3">
            </div>

            <div class="top-between">
                <div class="lineLeft_box_title">{{investmentProjects.name||'投资项目'}}</div>
                <div class="common_next_prev_box" v-if="investmentProjects.size>1">
                    <div class="cityAbility7_4_prev prev"></div>
                    <div class="cityAbility7_4_next next"></div>
                </div>
            </div>
            
            <div class="swiper-container info_list_box"  id="cityAbility7_4_swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-no-swiping" v-for="(item,i) in investmentProjects.list" :key="i">
                        <div class="tap_" v-for="tap in item" :key="tap.id"  @click="onInfoDetail(tap.id)">
                            <div class="img_box"><div :style="'background-image: url('+tap.thumbnail+')'"></div></div>
                            <div class="info">{{tap.title}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 智云应用 -->
        <div class="box_ home_8_box">
            <div class="home_box_title">
                <span>{{infoD.two_name||'智云应用'}}</span>
                <div class="english absol_">SMART CLOUD APPLICATION</div>
            </div>


            <!-- 政务应用 -->
            <div class="top-between">
                <div class="lineLeft_box_title" style="margin-top:0">{{governmentAppli.name||'政务应用'}}</div>  
                <div class="common_next_prev_box" v-if="governmentAppli.size>1">
                    <div class="cityAbility8_2_prev prev"></div>
                    <div class="cityAbility8_2_next next"></div>
                </div>
            </div>
            <div class="swiper-container info_list"  id="cityAbility8_2_swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-no-swiping" v-for="(item,i) in governmentAppli.list" :key="i">
                        <div class="tap_" v-for="tap in item" :key="tap.id" @click="onInfoDetail(tap.id)">
                            <div class="bg_img" :style="'background-image: url('+tap.thumbnail+')'"></div>
                            <div class="info text_line_middle"><span>{{tap.title}}</span></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 平台开发 -->
            <div class="lineLeft_box_title">{{platformDevel.name||'平台开发'}}</div>  
            <div class="table_box">
                <div class="left_">
                    <div class="text_line_middle" :style="{height:100/platformDevel.list.length+'%'}" 
                    :class="[{'active':select8_lastIndex==index},{'borderNone':platformDevel.list.length>3&&index == platformDevel.list.length-1}]"
                    v-for="(item,index) in platformDevel.list" :key="item.id" @click="select8_lastIndex=index" >
                        <span>{{item.title}}</span>
                    </div>
                </div>
                <div class="right_ src-right">
                    <div class="img_box" :style="'background-image: url('+select8_lastImg+')'"></div>
                </div>
            </div>
            
            <!-- 企业服务 -->
            <div class="lineLeft_box_title">{{enterpriseService.name||'企业服务'}}</div>  
            <div class="btn_box src-top">
                <div v-for="item in enterpriseService.list" :key="item.id">
                    <img :src="item.thumbnail">
                    <div>{{item.title}}</div>
                </div>
            </div>
        </div>

        <!-- 合作企业 -->
        <div class="box_ home_9_box">
            <div class="home_box_title">
                <span>{{cooperative_enterprise.name||'合作企业'}}</span>
                <div class="english absol_">COOPERATIVE ENTERPRISE</div>
            </div>
            <div class="desc_" v-html="cooperative_enterprise.desc"></div>
            <div v-for="item in cooperative_enterprise.list" :key="item.id">
                <div class="lineLeft_box_title">{{item.title}}</div>
                <div class="tab_list">
                    <div v-for="(img,i) in item.img" :key="i">
                        <div :style="'background-image: url('+img+')'"></div>
                    </div>
                </div>
            </div>
        </div>

        <footerComponent style="margin-top:80px"></footerComponent>
    </div>
</template>
<script>
import expertThinkTank from "./components/expert-think-tank";
export default {
    components:{expertThinkTank},
    name:'cityAbility',
    data(){
        return{
            selectPlatImg:'',
            selectPlatformIndex: null,
            select8_lastIndex: -1,
            select8_lastImg:'',

            banner:[],
            prefixImg:'',
            infoD:{},
            home2:{
                name:'',
                list:[]
            },
            home3: {},
            peakBbs:{
                list:[]
            },
            policyInter:{
                list:[]
            },
            industryResearch:{
                list:[]
            },
            aPark:{},
            sixPlat:{list:[]},
            threeBases:{list:[]},
            aCenter:{},
            industryFunds:{},
            investmentProjects:{
                name:'',
                list:{},
                size:0,
            },
            enterpriseService:{list:[]},
            governmentAppli:{
                name:'',
                list:{},
                size:0,
            },
            platformDevel:{list:[]},
            cooperative_enterprise:{
                name:'',
                desc: '',
                list:[],
            }
        }
    },
    created(){
        this.initData()
    },
    watch:{
        selectPlatformIndex:{
            handler(val){
                this.selectPlatImg = this.sixPlat.list[val]&& this.sixPlat.list[val].thumbnail;
                let el = this.$refs.cityAbility6_2_img;
                el.classList.add('active')
                setTimeout(() => {
                    el.classList.remove('active')
                }, 500);
            },
        },
        select8_lastIndex:{
            handler(val){
                this.select8_lastImg = this.platformDevel.list[val]&&this.platformDevel.list[val].thumbnail
            }
        }
    },
    methods:{
        initData(){
            this.$Request({
                url: '/portal/city_fu_can',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                this.prefixImg = data.url+'/';
                this.$set(this,'infoD',{
                    one_name: data.one_name,
                    two_name: data.two_name,
                    desc: data.city_fu_can_desc
                })
                //banner
                this.banner = this.$getListByObj(data.city_fu_can_banner).map(item=>{return item.image});
                if(this.banner.length>1){
                    this.$nextTick(()=>{
                        new Swiper ('#cityAbilityTop_swiper', {
                            pagination: '.cityAbilityTop-pagination',
                            paginationClickable :true,
                            autoplay: 5000,
                        })
                    })
                }
                //顶部
                let city_fu_can_icon = this.$initNes(data.city_fu_can_icon||{})
                this.home2.list = city_fu_can_icon.val;
                //专家智库
                let experts_think_tank = this.$initNes(data.experts_think_tank||{})
                this.$set(this, 'home3', {
                    name: experts_think_tank.name,
                    desc: data.desc,
                    list1: experts_think_tank.val.splice(0,8),
                    list2: experts_think_tank.val.splice(0,4),
                })
                //高峰论坛
                let peak_bbs = this.$initNes(data.peak_bbs||{}, 'desc')
                this.$set(this,'peakBbs',{
                    name: peak_bbs.name,
                    desc: peak_bbs.desc,
                    list: peak_bbs.val
                })
                //政策解读
                let policy_interpretation = this.$initNes(data.policy_interpretation||{})
                this.$set(this,'policyInter',{
                    name: policy_interpretation.name,
                    list: policy_interpretation.val,
                    category_id: policy_interpretation.category_id,
                })
                //产业研究
                let industry_research = this.$initNes(data.industry_research||{})
                this.$set(this,'industryResearch',{
                    name: industry_research.name,
                    list: industry_research.val,
                    category_id: industry_research.category_id,
                })
                //一园区
                let a_park = this.$initNes(data.a_park||{}),
                    a_park_detail = a_park.val[0]||{}
                this.$set(this,'aPark',{
                    name: a_park.name,
                    content: a_park_detail.content,
                    img: a_park_detail.thumbnail,
                    title: a_park_detail.title,
                })
                //六平台
                let six_platforms = this.$initNes(data.six_platforms||{})
                this.$set(this,'sixPlat',{
                    name: six_platforms.name,
                    list: six_platforms.val.map(item=>{
                        item.img_a = this.prefixImg + (item.img[0]&&item.img[0].url);
                        item.img_g = this.prefixImg + (item.img[1]&&item.img[1].url);
                        return item
                    })
                })
                this.selectPlatformIndex =0;
                //三基地
                let three_bases = this.$initNes(data.three_bases||{})
                this.$set(this,'threeBases',{
                    name: three_bases.name,
                    list: three_bases.val
                })
                //一中心
                let a_center = this.$initNes(data.a_center||{}),
                    a_center_detail = a_center.val[0]||{}
                this.$set(this,'aCenter',{
                    name: a_center.name,
                    content: a_center_detail.content,
                    img: this.prefixImg + (a_center_detail.img[0]&&a_center_detail.img[0].url),
                    title: a_center_detail.title,
                })
                //产业基金
                let industry_funds = this.$initNes(data.industry_funds||{})
                let o = {name: industry_funds.name}
                industry_funds.val.forEach((item,i)=>{
                    o[`name_${i+1}`] = item.title;
                    if(i==0)o.content_1 = item.content
                    if(i==1)o.img_2 = item.thumbnail
                    if(i==2)o.img_3 = item.thumbnail
                })
                this.$set(this,'industryFunds',o)

                //投资项目
                let investment_projects = this.$initNes(data.investment_projects||{})
                let invesList = investment_projects.val,arrInt = 1;
				if (invesList.length % 3 != 0) {
					arrInt = parseInt(invesList.length / 3) + 1;
				}else{
					arrInt = parseInt(invesList.length / 3)
                }
                
				for (let i = 0; i < arrInt; i++) {
					this.$set(this.investmentProjects.list, i, invesList.slice(i * 3, (i + 1) * 3));
                }
                this.investmentProjects.name = investment_projects.name;
                this.investmentProjects.size = arrInt;
                if(arrInt>1){
                    this.$nextTick(()=>{
                        new Swiper ('#cityAbility7_4_swiper', {
                            prevButton:'.cityAbility7_4_prev',
                            nextButton:'.cityAbility7_4_next',
                        })
                    })
                }
                //企业服务
                let enterprise_service = this.$initNes(data.enterprise_service||{})
                this.$set(this,'enterpriseService',{
                    name: enterprise_service.name,
                    list: enterprise_service.val
                })
                //政务应用
                let government_application = this.$initNes(data.government_application||{})
                let goverList = government_application.val,arr = 1;
				if (goverList.length % 3 != 0) {
					arr = parseInt(goverList.length / 3) + 1;
				}else{
					arr = parseInt(goverList.length / 3)
                }
                
				for (let i = 0; i < arr; i++) {
					this.$set(this.governmentAppli.list, i, goverList.slice(i * 3, (i + 1) * 3));
                }
                this.governmentAppli.name = government_application.name;
                this.governmentAppli.size = arr;
                if(arr>1){
                    this.$nextTick(()=>{
                        new Swiper ('#cityAbility8_2_swiper', {
                            prevButton:'.cityAbility8_2_prev',
                            nextButton:'.cityAbility8_2_next',
                        })
                    })
                }
                //平台开放
                let platform_development = this.$initNes(data.platform_development||{})
                this.select8_lastIndex = 0;
                this.$set(this,'platformDevel',{
                    name: platform_development.name,
                    list: platform_development.val
                })
                //合作企业
                let cooperative_enterprise =  this.$initNes(data.cooperative_enterprise||{});
                this.cooperative_enterprise.name = cooperative_enterprise.name;
                if(cooperative_enterprise.val.length>0){
                    this.cooperative_enterprise.list = cooperative_enterprise.val.map(item=>{
                        item.img =  (item.img||[]).map(img=>{
                            return this.prefixImg +''+img.url
                        })
                        return item
                    });
                    this.cooperative_enterprise.desc = cooperative_enterprise.val[0].content
                }  
                this.setScrol()
            })
        },
        onInfoDetail(id){
            this.$router.push({name:'infomationDetail', query:{id: id}})
        },
        onGetMore(name, id){
            this.$router.push({name:'infomationList', query:{name: name, id: id}})
        }
    },
    mounted(){
        // new Swiper ('#cityAbility6_1_swiper', {
        //     prevButton:'.cityAbility6_1_prev',
        //     nextButton:'.cityAbility6_1_next',
        //     onSlideChangeEnd: function(swiper){
        //         console.log(swiper.activeIndex)
        //     }
        // })
    },
}
</script>
<style lang="scss" scoped>
    .cityAbility{
        .pageTop_box{
            width: 100%;
            height: 450px;
        }
        .box_{
            width: 1200px;
            margin: 0 auto;
            padding-top: 1px;
            padding-bottom: 1px;
        }
       
        .info_list_box{
            margin-top: 24px;
            display: inline-block;
            width: 100%;
            .tap_{
                float: left;
                width: 385px;
                background: #cccccc;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                margin-bottom: 20px;
                cursor: pointer;
                &:not(:nth-child(3n)){
                    margin-right: 18px;
                }
                .img_box{
                    width: 100%;
                    height: 218px;
                    overflow: hidden;
                    &>div{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        transition: all .3s ease; 
                        background-color: #ccc; 
                    }
                }
                .info{
                    background-color: #fff;
                    padding: 15px 16px;
                    min-height: 80px;
                    font-size: 18px;
                    color: #333333;
                    line-height: 24px;
                    @include textOver(2)
                }
                &:hover{
                    .img_box>div{
                        transform: scale(1.2);
                    }
                }
            }
        }
        .home_2_box{
            margin-top: 70px;
            .desc_info{
                font-size: 14px;
                color: #333333;
                line-height: 21px;
            }
            .btn-list{
                margin-top: 16px;
                display: inline-block;
                width: 100%;
                &>div{
                    width: 384px;
                    height: 128px;
                    background: #F5F5F5;
                    float: left;
                    padding: 24px 20px 24px 36px;
                    &:not(:nth-child(3n)){
                        margin-right: 24px;
                    }
                    &>img{
                        vertical-align: middle;
                        width: 80px;
                        height: 80px;
                    }
                    &>span{
                        vertical-align: middle;
                        padding-left: 24px;
                        font-size: 24px;
                        color: #333333;
                        line-height: 21px;
                    }
                    .svg-border path{
                        @include svgBorderStroke(384, 128)
                    }
                    &:hover{
                        box-shadow: 0 4px 6px 0 rgba(0, 144, 77, 0.2), 0 9px 24px 0 rgba(0, 144, 77, 0.2);
                        background-color: #fff;
                        .svg-border path{
                        @include svgBorderStrokeHover(384, 128)
                        }
                    }
                }
            }
        }
        .home_3_box{
            padding-bottom: 40px;
        }
        .home_4_box{
            width: 100%;
            min-height: 690px;
            padding-bottom: 30px;
            @include backImg('../assets/img/test/cityAbility_4.png');
            .lineLeft_box_title{
                margin-top: 40px;
            }
            .info_{
                margin-top: 20px;
                font-size: 14px;
                color: #ffffff;
                line-height: 21px;
            }
            .list-box{
                margin-top: 55px;
                margin-left: 150px;
                color: #FFFFFF;
                display: inline-block;
                width: 890px;
                text-align: center;
                &>div{
                   width: 328px;
                   text-align: center;
                   float: left;
                   margin-bottom: 40px;
                   &:nth-child(2n){
                       float: right;
                   }
                   &>.img_box{
                       width: 100%;
                       height: 328px;
                       border-radius: 50%;
                       overflow: hidden;
                       >div{
                           width: 100%;
                           height: 100%;
                             @include backImg('../assets/img/home_6_1.png');
                            transition: all .2s ease;
                       }
                   }
                   .name{
                       margin-top: 22px;
                       font-size: 18px;
                       line-height: 21px;
                   }
                   .desc_{
                       margin-top: 10px;
                       font-size: 14px;
                       line-height: 21px;
                   }
                   &:hover{
                       .img_box>div{
                           transform: scale(1.05);
                       }
                   }
                }
            }
        }
        .home_5_box{
            margin-top: 43px;
            &.second_{
                margin-top: 23px;
            }
            .top_{
                display: inline-block;
                width: 100%;
                >.lineLeft_box_title{
                    float: left;
                }
                >button{
                    float: right;
                }
            }
            
        }
        .home_6_box{
            margin-top: 60px;
            >.one_box{
                margin-top: 40px;
                .content_{
                    margin-top: 23px;
                    width: 100%;
                    position: relative;
                    .box-tab{
                        width: 800px;
                        height: 500px;
                        position: relative;
                        margin: 0;
                        .img_box{
                            width: 100%;
                            height: 100%;
                            @include backImg('../assets/img/home_6_1.png');
                            background-color: #ccc; 
                        }
                        .next_prev_box{
                            position: absolute;
                            bottom: 24px;
                            right: 20px;
                            z-index: 99;
                            &>div{
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background-size: cover;
                                background-repeat: no-repeat;
                                &.prev{
                                    background-image: url('../assets/img/prev.png');
                                    @include rotate(0);
                                    cursor: pointer;
                                    &.swiper-button-disabled{
                                        background-image: url('../assets/img/next.png'); 
                                        @include rotate();   
                                        cursor: not-allowed;
                                    }
                                }
                                &.next{
                                    background-image: url('../assets/img/prev.png');
                                    @include rotate();
                                    margin-left: 20px;
                                    cursor: pointer;
                                    &.swiper-button-disabled{
                                        background-image: url('../assets/img/next.png'); 
                                        @include rotate(0);  
                                        cursor: not-allowed;
                                    }
                                }
                            }
                        }
                    }
                    .info_tap{
                        position: absolute;
                        right: 0;top: 0;
                        bottom: 0;
                        z-index: 1;
                        width: 490px;
                        height: 347px;
                        margin: auto 0;
                        @include backImg('../assets/img/home_6_1.png');
                        color: #fff;
                        line-height: 21px;
                        >div{
                            left: 0;right: 0;
                            position: absolute;
                            top: 50%; /*偏移*/
                            transform: translateY(-50%);
                            padding: 30px 35px;
                            max-height: 100%;
                            overflow-y: auto;
                        }
                        .name{
                            font-size: 20px;
                        }
                        .desc{
                            font-size: 14px;
                            margin-top: 20px;
                        }
                    }
                }
            }

            >.two_box{
                margin-top: 44px;
                .tob_box{
                    margin-top: 22px;
                    &>div{
                        width: 200px;
                        height: 150px;
                        background: #fff;  
                        padding-top: 26px;
                        text-align: center;
                        position: relative;
                        display: inline-block;
                        cursor: pointer;
                        &>div{
                            transition:all .2s ease;
                        }
                        img{
                            width: 61px;
                            height: 61px;
                            display: block;
                            margin: 0 auto;
                        }
                        .info{
                            font-size: 18px;
                            color: #333333;
                            line-height: 21px;
                            margin-top: 14px;
                        }
                        &:hover .info{
                            color: $lv;
                        }
                        &.active{
                            background: #F5F5F5;
                            &>div{
                                transform:translateY(-5px);
                            }
                            .info{
                                color: $lv;
                            }
                            &::after{
                               content: '';
                               position: absolute;
                               left: 0; right: 0;
                               bottom: 0;
                               width: 180px;
                               height: 4px;
                               background: $lv;
                               margin: 0 auto;
                            }
                        }
                    }
                }
                .content_{
                    width: 100%;
                    background: #F5F5F5;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
                    padding: 40px 0;
                    .img_box{
                        height: 640px;

                        &.active{
                            animation: slideInLeft 0.5s linear forwards;
                        }
                        &>div{
                            width: 100%;
                            height: 100%;
                            background-color: #ccc; 
                            @include backImg('../assets/img/home_6_1.png');
                        }
                    }
                }
            }

            >.three_box{
                margin-top: 44px;
                .list_box{
                    margin-top: 22px;
                    display: inline-block;
                    width: 100%;
                    &>div{
                        width: 388px;
                        height: 614px;
                        overflow-y: hidden;
                        background-color: #fff;
                        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                        float: left;
                        &:not(:nth-child(3n)){
                            margin-right: 18px;
                        }
                        .img_box{
                            width: 100%;
                            height: 388px;
                            overflow: hidden;
                            &>div{
                                width: 100%;
                                height: 100%;
                                @include backImg('../assets/img/home_6_1.png');
                                background-color: #ccc; 
                                transition: all .3s ease; 
                            }
                            
                        }
                        .name{
                            padding: 0 16px;
                            color: #333333;
                            font-size: 20px;
                            margin-top: 20px;
                            line-height: 21px;
                        }
                        .info{
                            margin-top: 15px;
                            padding: 0 16px;
                            font-size: 14px;
                            color: #6C6C6C;
                            line-height: 21px;
                        }
                        &:hover{
                            background-color:$lv;
                            .name,.info{
                                color: #fff;
                            }
                            .img_box>div{
                                transform: scale(1.2);
                            }
                        }
                    }
                }
            }
        }
        .home_7_box{
            margin-top: 75px;
            .lineLeft_box_title{
                margin-top: 40px;
            }
            .desc_info{
                font-size: 14px;
                color: #333333;
                line-height: 21px; 
            }
            .detail_img{
                margin-top: 20px;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                &>img{
                    display: block;
                    width: 100%;
                }
            }
            .top-between{
                position: relative;
            }
        }
        .home_8_box{
            margin-top: 60px;
            .lineLeft_box_title{
                margin-top: 40px;
            }
            .btn_box{
                margin-top: 40px;
                display: inline-block;
                width: 100%;
                >div{
                    width: 140px;
                    float: left;
                    color: #333333;
                    margin-bottom: 40px;
                    transition: all .2s ease;
                    &:not(:nth-child(5n)){
                        margin-right: 100px;
                    }
                    >img{
                        width: 120px;
                        height: 120px;
                        margin: 0 auto;
                        display: block;
                    }
                    >div{
                        font-size: 14px;
                        line-height: 21px;
                        text-align: center;
                        padding: 20px 0;
                    }
                    &:hover{
                        color: $lv;
                        transform: translateY(-10px)
                    }
                }
            }
            .top-between{
                position: relative;
                margin-top: 40px;
            }
            .info_list{
                margin-top: 20px;
                .tap_{
                    display: inline-block;
                    width: 385px;
                    height: 218px;
                    position: relative;
                    overflow: hidden;
                    text-align: center;
                    &:not(:nth-child(3n)){
                        margin-right: 18px;
                    }
                    .bg_img{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        background-color: #ccc; 
                        transition: all .3s ease; 
                    }
                    .info{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 60px;
                        background-color: rgba($color: #000000, $alpha: 0.6);
                        >span{
                            color: #fff;
                            font-size: 14px;
                        }

                    }
                    &:hover{
                        .bg_img{
                            transform: scale(1.2);
                        }
                    }
                }
            }
            .table_box{
                margin-top: 20px;
                width: 100%;
                height: 480px;
                background: #D2D2D2;
                box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.12);
                position: relative;
                .left_{
                   width: 300px;
                   height: 100%; 
                   >div{
                       text-align: center;
                       color: #333;
                       font-size: 18px;
                       max-height: 120px;
                       cursor: pointer;
                       border-bottom: 1px solid #fff;
                       &.borderNone{
                           border-bottom: none;
                       }
                       &:hover{
                           background: rgba($color: $lv, $alpha: 0.5);
                           color: #fff;
                       }
                       &.active{
                           background: $lv;
                           color: #fff;
                       }
                   }
                }
                .right_{
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: calc(100% - 300px);
                    .img_box{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        background-color: #ccc; 
                    }
                }
            }
        }
        .home_9_box{
            margin-top: 60px;
            .desc_{
                margin-top: 35px;
                font-size: 14px;
                color: #333333;
                line-height: initial;
            }
            .lineLeft_box_title{
                margin-top: 40px;
            }
            .tab_list{
                padding: 1px 0;
                >div{
                    margin-top: 20px;
                    display: inline-block;
                    width: 282px;
                    height: 86px;
                    background: #FFFFFF;
                    border: 1px solid #ccc;
                    >div{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        background-color: #cccccc;
                    }
                    &:not(:nth-child(4n)){
                        margin-right: 24px;
                    }
                    &:hover{
                        border-color: $lv;
                        box-shadow: 0px 8px 12px 0px rgba(0, 125, 81, 0.12);
                    }
                }
            }
        }
    }
    
</style>